<script setup>
import PublicLayout from "../../Layouts/PublicLayout.vue";
import { VDataTable } from "vuetify/labs/VDataTable";
import { Head, router, usePage } from "@inertiajs/vue3";
import { useLocale } from "vuetify";
import { computed, onMounted, ref } from "vue";
import { createPinia, getActivePinia } from "pinia";
import { usePreferencesStore } from "../../stores/preferencesStore";

defineOptions({ layout: PublicLayout });
const { t } = useLocale();
defineProps({ ranking: Array });

const sortBy = ref([{ key: "place", order: "asc" }]);

const headers = [
    { title: "", align: "start", key: "place" },
    { title: t("teams.number"), align: "start", key: "team_id" },
    { title: t("teams.name"), align: "start", key: "team_name" },
    { title: t("scoreboard.score"), align: "start", key: "max_score" }
];

const pinia = createPinia();
const preferences = usePreferencesStore(pinia);

const itemsPerPage = ref(50);

// Websocket implementation to refresh
onMounted(() => {
    Echo.channel("NewRefereeResult")
        .listen("NewRefereeResult", (e) => {
            console.log("Refreshing Data");
            router.reload();
        });
});

</script>


<template>
    <Head :title="$vuetify.locale.t('scoreboard.title')"></Head>

    <div class="tw-py-12">
        <div class="tw-max-w-7xl tw-mx-auto sm:tw-px-6 lg:tw-px-8">
            <h2
                style="padding: 10px; background-color: #37bd97"
                class="tw-text-center tw-text-white tw-overflow-hidden tw-shadow sm:tw-rounded-lg"
            >
                {{ $vuetify.locale.t("scoreboard.header") }}
            </h2>
            <div
                class="tw-mt-5 tw-bg-white dark:tw-bg-gray-900 tw-overflow-hidden tw-shadow sm:tw-rounded-lg"
            >
                <v-data-table
                    :theme="preferences.state.darkMode ? 'dark' : 'light'"
                    v-model:items-per-page="itemsPerPage"
                    v-model:sort-by="sortBy"
                    :headers="headers"
                    :items="ranking"
                    item-value="place"
                    class="elevation-1"
                    hide-default-footer
                    hover
                >
                    <template v-slot:bottom></template>
                </v-data-table>
            </div>
        </div>
    </div>
</template>
